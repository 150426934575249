import './overlay.scss';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { createElementFromHTML } from './../../utilities/js/helper';

class Overlay {
    constructor (options) {
        const defaults = {
            loader: null,
            speed: 125,
            contentClass: null
        };

        this.settings = Object.assign({}, defaults, options);
        this.isVisible = false;

        if (this.settings.loader !== null) {
            this.$loader = createElementFromHTML(this.settings.loader);
        }

        this.initialize();
    }

    initialize () {
        const $body = document.querySelector('body');
        this.$overlay = document.createElement('div');
        this.$overlay.classList.add('overlay');

        this.$overlayContent = document.createElement('div');
        this.$overlayContent.classList.add('overlay__content');

        if (this.settings.contentClass !== null) {
            this.$overlayContent.classList.add(this.settings.contentClass);
        }

        this.$overlayScroller = document.createElement('div');
        this.$overlayScroller.classList.add('overlay__scroller');

        this.$overlayClose = document.createElement('a');
        this.$overlayClose.classList.add('overlay__close');
        this.$overlayClose.classList.add('icon-close');

        this.$overlayScroller.appendChild(this.$overlayContent);

        this.$overlay.appendChild(this.$overlayScroller);
        this.$overlay.appendChild(this.$overlayClose);

        $body.appendChild(this.$overlay);

        this.setEvents();
    }

    setEvents () {
        this.$overlayClose.addEventListener('click', () => {
            this.destroy();
        });

        if (window.browser.csshover) {
            this.$overlay.addEventListener('click', (e) => {
                this.destroy();
            });
        }
    }

    setContent ($content) {
        this.$overlayContent.innerHTML = '';
        this.$overlayContent.appendChild($content);
    }

    show (callback) {
        this.$overlay.classList.add('is--visible');
        this.isVisible = true;

        disableBodyScroll(this.$overlayScroller);

        window.setTimeout(() => {
            if (typeof callback === 'function') {
                callback();
            }
        }, this.settings.speed);
    }

    hide (callback) {
        this.$overlay.classList.remove('is--visible');
        this.isVisible = true;

        enableBodyScroll(this.$overlayScroller);

        window.setTimeout(() => {
            if (typeof callback === 'function') {
                callback();
            }
        }, this.settings.speed);
    }

    destroy () {
        if (this.isVisible === false) {
            this.$overlay.parentNode.removeChild(this.$overlay);
        } else {
            this.hide(() => {
                this.$overlay.parentNode.removeChild(this.$overlay);
            });
        }
    }
}

export { Overlay };
