import './image-zoom.scss';

import { createElementFromHTML, loadImage } from './../../utilities/js/helper';
import { Overlay } from './../../components/overlay/overlay';

class ImageZoom {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-imagezoom',
            imageSrc: null
        };

        this.settings = Object.assign({}, defaults, options);

        this.$imageZoom = element;
        this.imageSrc = this.settings.imageSrc;

        if (window.loader !== null) {
            this.$loader = createElementFromHTML(window.loader);
        }
    }

    initialize () {
        this.setEvents();
    }

    setEvents () {
        this.$imageZoom.addEventListener('click', (e) => {
            this._buildOverlay();

            e.preventDefault();
        });
    }

    _buildOverlay () {
        this.$imageZoom.classList.add('is--loading');
        if (this.$loader) {
            this.$imageZoom.appendChild(this.$loader);
        }

        const overlay = new Overlay({
            loader: window.loader,
            contentClass: 'image-zoom'
        });

        const $image = document.createElement('img');
        $image.src = this.imageSrc;

        loadImage($image, (image) => {
            overlay.setContent(image);
            overlay.show();

            this.$imageZoom.classList.remove('is--loading');
            if (this.$loader) {
                this.$loader.parentNode.removeChild(this.$loader);
            }

            const distanceHorizontal = overlay.$overlayContent.offsetWidth - window.innerWidth;
            const distanceVertical = overlay.$overlayContent.offsetHeight - window.innerHeight;

            overlay.$overlayScroller.scrollLeft = 0.5 * distanceHorizontal;
            overlay.$overlayScroller.scrollTop = 0.5 * distanceVertical;
        });
    }
}

export { ImageZoom };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$roots = $context.querySelectorAll('[data-imagezoom="root"]');
        if ($$roots.length > 0) {
            $$roots.forEach($root => {
                const imageSrc = this.$root.getAttribute('href');

                if (imageSrc) {
                    const $rootAPI = new ImageZoom($root, {
                        imageSrc
                    });
                    $rootAPI.initialize();
                }
            });
        }
    }
});
